import React from 'react';


function Footer() {
    return (
    <footer className="footer">
    </footer>

    )
}

export default Footer;
