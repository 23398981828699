export const ADD_BOT_STARTED = "ADD_BOT_STARTED"
export const ADD_BOT_SUCCESS = "ADD_BOT_SUCCESS"
export const ADD_BOT_ERROR = "ADD_BOT_ERROR"
export const SET_BOT_STARTED = "SET_BOT_STARTED"
export const SET_BOT_SUCCESS = "SET_BOT_SUCCESS"
export const SET_BOT_ERROR = "SET_BOT_ERROR"
export const GET_BOT_STARTED = "GET_BOT_STARTED"
export const GET_BOT_SUCCESS = "GET_BOT_SUCCESS"
export const GET_BOT_ERROR = "GET_BOT_ERROR"
export const SHOW_ADD_FIELD = "SHOW_ADD_FIELD"
export const HIDE_ADD_FIELD = "HIDE_ADD_FIELD"
export const STEAMGUARD_BOT_STARTED = "STEAMGUARD_BOT_STARTED"
export const STEAMGUARD_BOT_SUCCESS = "STEAMGUARD_BOT_SUCCESS"
export const STEAMGUARD_BOT_ERROR = "STEAMGUARD_BOT_ERROR"
export const REMOVE_BOT_STARTED = "REMOVE_BOT_STARTED"
export const REMOVE_BOT_SUCCESS = "REMOVE_BOT_SUCCESS"
export const REMOVE_BOT_ERROR = "REMOVE_BOT_ERROR"